import React, { useState, useEffect, useRef } from 'react'
import './ConfiguratorPage.css'
import InfoIcon from '@mui/icons-material/Info'
import ChoiceElement from '../components/ChoiceElement'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { configuratorData } from '../data/configurationData'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

function ConfiguratorPage() {
  const [data, setData] = useState([configuratorData[0]])
  const [totalPages, setTotalPages] = useState(0)
  const [popupActive, setPopupActive] = useState(false)
  const [page, setPage] = useState(0)
  const [selectedChoice, setselectedChoice] = useState(null)
  const [isContentWider, setIsContentWider] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [selectedChoices, setselectedChoices] = useState({
    "material": "",
    "type": "",
    "style": "",
    "options": []
  })

  const scrollContainerRef = useRef(null)
  const pagesContainerRef = useRef(null)

  const handleScroll = (scrollOffset) => {
    scrollContainerRef.current.scrollLeft += scrollOffset;
  }

  const checkContentWidth = () => {
    const popupImagesContainer = scrollContainerRef.current
    if (popupImagesContainer) {
      const popupWindow = popupImagesContainer.closest('.popup__window')
      if (popupWindow) {
        setIsContentWider(popupImagesContainer.scrollWidth > popupWindow.offsetWidth)
      }
    }
  }

  useEffect(() => {
    setTotalPages(data.length)
  }, [data])

  useEffect(() => {
    checkContentWidth()
  }, [popupActive])

  const handleImageLoad = () => {
    checkContentWidth() // Recheck content width after an image is loaded
  }

  useEffect(() => {
    const handleResize = () => checkContentWidth()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleOnClickChoice = (event) => {
    const choice = event.currentTarget.getAttribute("data-choice")
    const currentPageTopic = data.find(obj => obj["page"] == data[page]["page"]).page

    // update selected choices object
    const newStateChoices = selectedChoices
    newStateChoices[currentPageTopic] = choice
    setselectedChoices(newStateChoices)

    // add next page to data
    const nextPage = configuratorData.find(obj => obj["comesAfterChoice"] == choice)
    if (nextPage != undefined) {
      const newStateData = [...data]
      newStateData.push(nextPage)
      setData(newStateData)

      // update page
      setPage((prePage) => (prePage + 1))
    } else {
      console.log("end of configurator")
    }
  }

  const handleGoBack = () => {
    // remove current page from data
    const newStateData = data.slice(0, -1)
    setData(newStateData)

    // update page
    setPage((prePage) => (prePage - 1))
  }

  return (
    <div className='configurator__container'>
      {
        popupActive &&
        <div className={`popup__window ${popupActive ? "active" : ""}`}>
          <div className='popup__close_icon' onClick={() => setPopupActive(false)}>x</div>

          <div className='popup__title'>{data[page]["popupInfo"][selectedChoice].title}</div>
          <div className='popup__text' dangerouslySetInnerHTML={{ __html: data[page]["popupInfo"][selectedChoice].content }} />
          <button className={`back_button ${isContentWider ? "" : "hide"}`} onClick={() => handleScroll(-500)}><ArrowBackIosIcon /></button>
          <button className={`forward_button ${isContentWider ? "" : "hide"}`} onClick={() => handleScroll(500)}><ArrowForwardIosIcon /></button>
          <div className='popup__images' ref={scrollContainerRef}>
            {
              data[page]["popupInfo"][selectedChoice].images.map((image, index) => {
                return (
                  <img src={image} key={index} onLoad={handleImageLoad} />
                )
              })
            }
          </div>
        </div>
      }

      <div className='configurator__title'>{data[page]["pageTitle"]}</div>
      <div
        className='configurator_pages__container'
        ref={pagesContainerRef}
        style={{ transform: `translateX(-${page * 100}%)` }}
      >
        {
          data.map((page, index) => {
            return (
              <div className='configurator_page' key={index}>

                <div className='choices__wrapper'>
                  {
                    page["choices"].map((choice, index) => {
                      return (
                        <ChoiceElement
                          image={choice.image}
                          title={choice.title}
                          onClick={handleOnClickChoice}
                          setPopupActive={setPopupActive}
                          setselectedChoice={setselectedChoice}
                          index={index}
                          key={index}
                        />
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      <button className={`back_button--pages ${page == 0 | popupActive ? "hide" : ""}`} onClick={handleGoBack}><KeyboardBackspaceIcon />ga terug</button>

    </div>

  )
}

export default ConfiguratorPage