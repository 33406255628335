import React, {useState, useEffect} from 'react'
import './Footer.css'

function Footer() {
    return (
        <div className='footer__container'>
          <p className='copyright'>© 3B-Projects 2023</p>
          <div>|</div>
          <p>Bruwaanstraat 17, 8755 Ruiselede</p>
          <div>|</div>
          <a href="mailto: info@poucke.com">info@poucke.com</a>
          <div>|</div>
          <p>+32 51 43 30 43</p>
          <div>|</div> 
        </div>
    )
}

export default Footer