import React, { useState, useEffect } from 'react'
import './Product.css'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

function Product(props) {

  const [hovered, setHovered] = useState(false)
  const [atFirstSlide, setAtFirstSlide] = useState(true)
  const [atLastSlide, setAtLastSlide] = useState(false)

  const index = props.index

  const handleHover = () => {
    setHovered(!hovered)
  }

  useEffect(() => {
    const track = document.querySelector(`#carousel__track--${index}`)
    const slides = Array.from(track.children)
    const slideWidth = slides[0].getBoundingClientRect().width

    const setSlidePosition = (slide, indexSlide) => {
      slide.style.left = slideWidth * indexSlide + 'px'
    }
    slides.forEach(setSlidePosition)

    // listen for changes in screen size
    const handleResize = () => {
      const slideWidth = slides[0].getBoundingClientRect().width
      slides.forEach(setSlidePosition)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const moveToSlide = (track, currentSlide, targetSlide) => {
    const slides = Array.from(track.children)

    track.style.transform = 'translateX(-' + targetSlide.style.left + ')'
    currentSlide.classList.remove('current__slide')
    targetSlide.classList.add('current__slide')

    const slideIndex = slides.findIndex(slide => slide === targetSlide)
    if (slideIndex == 0) {
      setAtFirstSlide(true)
    } else {
      setAtFirstSlide(false)
    }
    if (slideIndex == slides.length - 1) {
      setAtLastSlide(true)
    } else {
      setAtLastSlide(false)
    }
  }

  const moveSlideRight = (event) => {
    const track = document.querySelector(`#carousel__track--${index}`)
    const currentSlide = track.querySelector('.current__slide')
    const nextSlide = currentSlide.nextElementSibling

    moveToSlide(track, currentSlide, nextSlide)
  }

  const moveSlideleft = (event) => {
    const track = document.querySelector(`#carousel__track--${index}`)
    const currentSlide = track.querySelector('.current__slide')
    const prevSlide = currentSlide.previousElementSibling

    moveToSlide(track, currentSlide, prevSlide)
  }

  return (
    <div className='product__container' onMouseEnter={handleHover} onMouseLeave={handleHover} >

      <div className='carousel'>

        <button className={`carousel__button carousel__button--left ${hovered ? "visible" : ""} ${atFirstSlide ? "hidden" : ""}`} onClick={moveSlideleft} ><NavigateBeforeIcon /></button>
        <div className='carousel__track-container'>
          <ul className='carousel__track' id={`carousel__track--${index}`}>
            {
              props.img_src_array.map((img_src, index) => {
                return (
                  <li className={index == 0 ? 'carousel__slide current__slide' : 'carousel__slide'} key={index}>
                    <img src={img_src} alt='product image poucke fences' className='carousel__image' />
                  </li>
                )
              })
            }
          </ul>
        </div>

        <button className={`carousel__button carousel__button--right ${hovered ? "visible" : ""} ${atLastSlide ? "hidden" : ""}`} onClick={moveSlideRight} ><NavigateNextIcon /></button>
      </div>

      <div className='product__details__container'>
        <h2>{props.title}</h2>
        <p>{props.text}</p>
        <p><strong>Ideaal voor:</strong> {props.ideal_for}</p>
      </div>
    </div>
  )
}

export default Product