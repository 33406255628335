import React, { useRef, useEffect, useState } from 'react'
import './AboutText.css'

function AboutText(props) {
  const aboutRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const element = aboutRef.current
      const elementPosition = element.getBoundingClientRect().top

      const windowHeight = window.innerHeight

      if (elementPosition < windowHeight) {
        element.classList.add('about__text-container--visible')
      }
      if (elementPosition > windowHeight) {
        element.classList.remove('about__text-container--visible')
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])


  return (
    <div className='about__text-container' ref={aboutRef} >
      <img
        src={`./img/${props.imageName}.jpg`} alt='placeholder'
        className={'about__image'}
      />
      <div className='about__text'>
        <h2>{props.title}</h2>
        {props.text}
      </div>
    </div>
  )
}

export default AboutText