import './App.css'
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Redirect } from "react-router-dom"

import HomePage from './pages/HomePage'
import ErrorPage from './pages/ErrorPage'
import Navbar from './components/Navbar'
import ConfiguratorPage from './pages/ConfiguratorPage'
import Footer from './components/Footer';
import CookiePolicyPage from './pages/CookiePolicyPage';

function App() {


  return (
    <div className='template'>

      <Router>

        <header>
          <Navbar/>
        </header>
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/cookie-policy" element={<CookiePolicyPage/>} />
          <Route path="/dev124578" element={<ConfiguratorPage />} />
        </Routes>
        
      </Router>

      <footer>
        <Footer/>
      </footer>

    </div>
  )
}

export default App
