import React, { useState, useEffect } from 'react'
import './CookiePolicyPage.css'

function CookiePolicyPage() {
  return (
    <div className='cookie_policy__container'>
      <h1>Cookiebeleid</h1>
      <p>Laatst bijgewerkt: 27/08/2023</p>

      <p>Dit Cookiebeleid legt uit wat cookies zijn, hoe we ze op onze website gebruiken en hoe u uw cookievoorkeuren kunt beheren. Lees dit beleid zorgvuldig door om meer te weten te komen over het gebruik van cookies op onze site.</p>

      <h2>1. Wat zijn cookies?</h2>
      <p>Cookies zijn kleine tekstbestanden die op uw computer of mobiele apparaat worden geplaatst wanneer u websites bezoekt. Ze worden veel gebruikt om websites effectiever te laten werken en om informatie te verstrekken aan de eigenaars van de site. Cookies stellen de site in staat om uw acties en voorkeuren (zoals inloggegevens, taal, lettergrootte en andere weergavevoorkeuren) te onthouden, zodat u deze niet opnieuw hoeft in te voeren wanneer u terugkeert naar de site of van de ene pagina naar de andere navigeert.</p>

      <h2>2. Hoe gebruiken we cookies?</h2>
      <p>We gebruiken verschillende soorten cookies op onze website voor verschillende doeleinden:</p>
      <ul>
        <li><strong>Noodzakelijke cookies:</strong> Deze cookies zijn essentieel voor het functioneren van de website en stellen basisfuncties zoals pagina-navigatie en toegang tot beveiligde gebieden mogelijk. Ze slaan geen persoonlijk identificeerbare informatie op.</li>
        <li><strong>Prestatiecookies:</strong> Deze cookies helpen ons begrijpen hoe bezoekers onze website gebruiken, zodat we de prestaties kunnen verbeteren. Ze verzamelen anonieme informatie zoals bezochte pagina's en klikgedrag.</li>
        <li><strong>Functionele cookies:</strong> Deze cookies onthouden uw voorkeuren en keuzes, waardoor uw ervaring op onze site wordt verbeterd. Ze kunnen bijvoorbeeld uw taalvoorkeur of ingelogde status onthouden.</li>
        <li><strong>Gerichte cookies:</strong> Deze cookies volgen uw surfgedrag om advertenties aan te passen aan uw interesses. Ze worden vaak gebruikt voor marketingdoeleinden.</li>
      </ul>

      <h2>3. Uw cookievoorkeuren beheren</h2>
      <p>U heeft de mogelijkheid om uw cookievoorkeuren te beheren en cookies te blokkeren of te verwijderen via uw browserinstellingen. Houd er rekening mee dat het blokkeren van cookies van invloed kan zijn op de werking van sommige delen van onze website. Raadpleeg de helpsectie van uw browser of de documentatie van uw apparaat voor meer informatie over het beheren van cookies.</p>

      <h2>4. Wijzigingen in dit beleid</h2>
      <p>Dit Cookiebeleid kan periodiek worden bijgewerkt om te voldoen aan veranderingen in wet- en regelgeving of om ons cookiegebruik aan te passen. We raden u aan om regelmatig terug te keren naar deze pagina om op de hoogte te blijven van eventuele wijzigingen.</p>

      <h2>5. Contact met ons opnemen</h2>
      <p>Als u vragen heeft over ons Cookiebeleid, neem dan contact met ons op via info@poucke.com.</p>
    </div>
  )
}

export default CookiePolicyPage